import React, { useState, useEffect } from 'react';
import RSSFeedDisplay from './RSSFeedDisplay';
import './App.css';

const ToggleSwitch = ({ isOn, handleToggle }) => {
  return (
    <div className="toggle-switch-container">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="toggle-switch-checkbox"
        id="toggle-switch"
        type="checkbox"
      />
      <label className="toggle-switch-label" htmlFor="toggle-switch">
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [viewCount, setViewCount] = useState(null);

  useEffect(() => {
    // Update dark mode
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    // Fetch view count when component mounts
    fetchViewCount();
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const fetchViewCount = async () => {
    try {
      const response = await fetch('https://mpshi9kml0.execute-api.ap-southeast-1.amazonaws.com/viewcount');
      if (!response.ok) {
        throw new Error('Failed to fetch view count');
      }
      const data = await response.json();
      setViewCount(data.viewCount);
    } catch (error) {
      console.error('Error fetching view count:', error);
    }
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : ''}`}>
       <header className="app-header">
          <div className="header-content">
            <div className="left-section">
              <h1>Cloud Service Provider RSS Feeds</h1>
              <p className="subheading">
                Learn about announcements, launches, news, innovation and more on AWS, Google Cloud, Microsoft Azure... 
              </p>
            </div>
            <div className="right-section">
              {/* <div className="view-count">
                <span className="view-count-label">Weekly Views:</span>
                <span className="view-count-number">{viewCount !== null ? viewCount : 'Loading...'}</span>
              </div> */}
              <div className="dark-mode-toggle">
                <span>{darkMode ? 'Dark Mode' : 'Light Mode'}</span>
                <ToggleSwitch isOn={darkMode} handleToggle={toggleDarkMode} />
              </div>
            </div>
          </div>
        </header>
      <div className="feed-container">
        <RSSFeedDisplay 
          feedUrl="https://aws.amazon.com/about-aws/whats-new/recent/feed/"
          title="Amazon Web Services"
          provider="aws"
          logoUrl="/images/aws-logo.png"
        />
        <RSSFeedDisplay 
          feedUrl="https://cloudblog.withgoogle.com/rss/"
          title="Google Cloud"
          provider="google"
          logoUrl="/images/google-cloud-logo.png"
        />
        <RSSFeedDisplay 
          feedUrl="https://azure.microsoft.com/en-us/blog/feed/"
          title="Microsoft Azure"
          provider="azure"
          logoUrl="/images/azure-logo.png"
        />
      </div>
      <footer className="creator-attribution">
        <span>Created by Kai Jian</span>
        <span>&copy; 2024 (v1.1)</span>
      </footer>
    </div>
  );
};

export default App;