import React, { useState, useEffect } from 'react';

const RSSFeedDisplay = ({ feedUrl, title, provider, logoUrl }) => {
  const [feedItems, setFeedItems] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRSS = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const API_GATEWAY_URL = 'https://lu3ia6zt3f.execute-api.ap-southeast-1.amazonaws.com/prod';
        const response = await fetch(`${API_GATEWAY_URL}/rss?url=${encodeURIComponent(feedUrl)}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFeedItems(data.items || []);
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
        setError('Failed to fetch RSS feed. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRSS();
  }, [feedUrl]);

  if (isLoading) {
    return <div className={`rss-feed ${provider} loading`}>Loading {title} feed...</div>;
  }

  if (error) {
    return (
      <div className={`rss-feed ${provider} error`}>
        <h2>{title}</h2>
        <p className="error-message">{error}</p>
      </div>
    );
  }

  return (
    <div className={`rss-feed ${provider}`}>
      <div className="rss-feed-header">
        <div className="rss-feed-title">{title}</div>
        <div className="company-logo-container">
          <img src={logoUrl} alt={`${title} logo`} className="company-logo" />
        </div>
      </div>
      <div className="rss-feed-content">
        {!feedItems || feedItems.length === 0 ? (
          <p>No items found in the feed.</p>
        ) : (
          <ul>
            {feedItems.map((item, index) => (
              <li key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
                <p>{new Date(item.pubDate).toLocaleDateString()}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RSSFeedDisplay;